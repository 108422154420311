// TODO: Replace with metricFragmentUtils

import { useMemo } from 'react';

import { CurrencyCode, DateFormat, formatDateWithLocalTime, Locale } from '@attentive/locale-utils';

import { Dimension, Metric, TimeDimension, MetricDataType, LegacyMetric } from '../services';

import { useIsEmailAnalyticsEnabled } from './featureFlagHooks';
import { formatCurrency, formatPercent, MINIMUM_ROUNDED_VALUE } from './formatUtils';
import { round } from './round';

export const METRIC_MISSING_DATA_SYMBOL = '-';

const isTimeDimension = (dimension: Dimension): boolean => {
  return dimension.dimensionId === 'date' || dimension.__typename === 'TimeDimension';
};

export const getMetricTimeDimension = (metric: Metric): TimeDimension | undefined => {
  const dateDimensionWrapper = metric.definition.dimensionWrappers.find((w) =>
    isTimeDimension(w.dimension)
  );
  if (dateDimensionWrapper) {
    return dateDimensionWrapper.dimension as TimeDimension;
  }
  return undefined;
};

export const formatMetricValue = ({
  value,
  dataType,
  currencyCode = CurrencyCode.US,
  locale,
  enableRounding = true,
}: {
  value: number | string | null;
  dataType: MetricDataType;
  locale: Locale;
  enableRounding?: boolean;
  currencyCode?: CurrencyCode;
}): string => {
  if (value === null) {
    return METRIC_MISSING_DATA_SYMBOL;
  }

  switch (dataType) {
    case MetricDataType.MetricDataTypeCurrency: {
      return formatCurrency({
        value,
        locale,
        currencyCode,
        hasDecimals: true,
      });
    }
    case MetricDataType.MetricDataTypePercent: {
      return formatPercent(value, enableRounding);
    }
    case MetricDataType.MetricDataTypeNumber: {
      if (!enableRounding) {
        return value.toLocaleString(locale, {
          maximumFractionDigits: 20,
          minimumFractionDigits: 0,
        });
      }
      const roundedValue = round(parseFloat(`${value}`), 2);
      if (roundedValue === 0 && value !== 0) {
        return `< ${MINIMUM_ROUNDED_VALUE}`;
      }
      return roundedValue.toLocaleString();
    }
    default: {
      return value.toLocaleString(locale, {
        maximumFractionDigits: 20,
        minimumFractionDigits: 0,
      });
    }
  }
};

export const formatMetricDimensionValue = ({
  value,
  dataType,
}: {
  value: string | null;
  dataType: Dimension['__typename'];
}): string | number => {
  if (value === null) {
    return `${value}`;
  }

  switch (dataType) {
    case 'TimeDimension': {
      // if this is an incorrect date format, formatDateWithLocalTime will throw an error
      // this happens when the user changes the number of groupings and the actual data hasn't returned form the backend
      try {
        return formatDateWithLocalTime(value, DateFormat.ISO_8601, Locale.enUS);
      } catch {
        return value.toLocaleString();
      }
    }
    default: {
      return value.toLocaleString();
    }
  }
};

export const useIsMultiSourceMetric = ({ sources }: LegacyMetric): boolean => {
  const isEmailEnabled = useIsEmailAnalyticsEnabled();
  const hasMultiSources = useMemo(
    () => Boolean(sources?.find(({ groupByDate }) => groupByDate)),
    [sources]
  );
  return isEmailEnabled && hasMultiSources;
};

export const extractSingleMetricAggregateValue = (
  aggregateValueMetrics:
    | ReadonlyArray<{ aggregateValue: number | null | undefined }>
    | null
    | undefined
): number => {
  return aggregateValueMetrics?.[0]?.aggregateValue || 0;
};
