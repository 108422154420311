/**
 * @generated SignedSource<<77c5f202428ed0ba33b651eb96540e35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useAiProReportingStates_ReportingPlatform_query$data = {
  readonly aiProSubscriptionCompanyData: {
    readonly aiProSubscriptionStatus?: {
      readonly status: {
        readonly status: {
          readonly __typename: "AiProSubscriptionStatusChurned";
        } | {
          readonly __typename: "AiProSubscriptionStatusPaused";
        } | {
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          readonly __typename: "%other";
        } | null;
      };
    } | null;
  } | null;
  readonly identityAiEatSubscribesData: {
    readonly identityAiEatSubscribes?: {
      readonly aggregateValues: ReadonlyArray<{
        readonly value: number | null;
      }>;
    } | null;
  } | null;
  readonly " $fragmentType": "useAiProReportingStates_ReportingPlatform_query";
};
export type useAiProReportingStates_ReportingPlatform_query$key = {
  readonly " $data"?: useAiProReportingStates_ReportingPlatform_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAiProReportingStates_ReportingPlatform_query">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "companyId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useAiProReportingStates_ReportingPlatform_query",
  "selections": [
    {
      "alias": "identityAiEatSubscribesData",
      "args": (v0/*: any*/),
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "identityAiEatSubscribes",
              "args": [
                {
                  "kind": "Literal",
                  "name": "filters",
                  "value": [
                    {
                      "dimensionId": "is_identity_ai",
                      "list": {
                        "values": [
                          "true"
                        ]
                      },
                      "operator": "FILTER_OPERATOR_IN"
                    }
                  ]
                },
                {
                  "kind": "Literal",
                  "name": "metricIds",
                  "value": [
                    "enhanced_attentive_tag_subscribes"
                  ]
                }
              ],
              "concreteType": "MetricsTableConnection",
              "kind": "LinkedField",
              "name": "metricsTable",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MetricValue",
                  "kind": "LinkedField",
                  "name": "aggregateValues",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "value",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "metricsTable(filters:[{\"dimensionId\":\"is_identity_ai\",\"list\":{\"values\":[\"true\"]},\"operator\":\"FILTER_OPERATOR_IN\"}],metricIds:[\"enhanced_attentive_tag_subscribes\"])"
            }
          ],
          "type": "Company",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "aiProSubscriptionCompanyData",
      "args": (v0/*: any*/),
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GetAiProSubscriptionStatusPayload",
              "kind": "LinkedField",
              "name": "aiProSubscriptionStatus",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AiProSubscriptionStatus",
                  "kind": "LinkedField",
                  "name": "status",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "status",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": (v1/*: any*/),
                          "type": "AiProSubscriptionStatusChurned",
                          "abstractKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": (v1/*: any*/),
                          "type": "AiProSubscriptionStatusPaused",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Company",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "ed313f41529ad1573e5b0c1862145227";

export default node;
