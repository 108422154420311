import { faker } from '@faker-js/faker';
import { format, sub } from 'date-fns';
import moment from 'moment';

import { MOMENT_ISO_8601_DATE_FORMAT, DATE_FNS_ISO_8601_DATE_FORMAT } from '@attentive/picnic';

import {
  LegacyMetric,
  LegacySourceDimension,
  LegacyMetricDomain,
  LegacyDateDimension,
  LegacyMetricId,
  LegacyDataError,
  LegacyMetricType,
  LegacyMetricConnotation,
  TimeGranularity,
} from '../../services';
import { round } from '../../utils';

interface CreateDomainParams {
  startDate: string;
  endDate: string;
  metricIds?: LegacyMetricId[];
  timeGranularity?: TimeGranularity;
}

interface CreateMetricParams {
  startDate?: string;
  endDate?: string;
  metricName?: string;
  metricId?: string;
  errors?: LegacyDataError[];
  type?: LegacyMetricType;
  sourceDimensionCount?: number;
  connotation?: LegacyMetricConnotation;
  timeGranularity?: TimeGranularity;
}

export const LegacyMetricFactory = {
  createDomain: ({
    startDate,
    endDate,
    metricIds: metricIds,
    timeGranularity = TimeGranularity.DAY,
  }: CreateDomainParams): LegacyMetricDomain => {
    const metricsToBuild = metricIds?.length
      ? metricIds
      : [...Array(faker.datatype.number({ min: 2, max: 6 }))];

    const name = metricIds?.length ? 'Filtered Domain' : `Domain ${faker.datatype.number()}`;

    return {
      name,
      id: '1',
      disclaimer: faker.lorem.sentence(20),
      metrics: metricsToBuild.map((metricName) =>
        LegacyMetricFactory.createMetric({ startDate, endDate, metricName, timeGranularity })
      ),
      allTime: metricsToBuild.map((metricName) =>
        LegacyMetricFactory.createMetric({ startDate, endDate, metricName, timeGranularity })
      ),
    };
  },
  createMetric: ({
    startDate = format(
      sub(new Date(), {
        days: 30,
      }),
      DATE_FNS_ISO_8601_DATE_FORMAT
    ),
    endDate = format(new Date(), DATE_FNS_ISO_8601_DATE_FORMAT),
    metricName,
    metricId,
    type = 'number',
    connotation,
    errors = [],
    sourceDimensionCount,
    timeGranularity = TimeGranularity.DAY,
  }: CreateMetricParams): LegacyMetric => {
    const numDataPoints =
      moment(endDate).diff(startDate, timeGranularity === TimeGranularity.DAY ? 'days' : 'months') +
      1;
    const numSourceDimensions = sourceDimensionCount ?? faker.datatype.number({ min: 1, max: 8 });
    const name = metricName ?? `Metric ${faker.datatype.number()}`;
    const id = metricId ?? faker.datatype.uuid();
    return {
      id: id as LegacyMetricId,
      name,
      description: faker.lorem.sentence(20),
      total: faker.datatype.number(),
      type,
      dataType: 'integer',
      connotation,
      sources: [...Array(numSourceDimensions)].map(() =>
        LegacyMetricFactory.createMetricSourceDimension(sourceDimensionCount)
      ),
      groupByDate: [...Array(numDataPoints)].map((_, i) =>
        LegacyMetricFactory.createMetricValue({
          date: moment()
            .subtract(i, timeGranularity === TimeGranularity.DAY ? 'days' : 'months')
            .format(MOMENT_ISO_8601_DATE_FORMAT),
        })
      ),
      errors,
    };
  },
  createComparisonMetric: ({ metric }: { metric: LegacyMetric }): LegacyMetric => {
    const endDate = metric.groupByDate.length > 0 ? metric.groupByDate[0].date : undefined;
    const startDate =
      metric.groupByDate.length > 0
        ? metric.groupByDate[metric.groupByDate.length - 1].date
        : undefined;
    const { name, id } = metric;
    const comparisonMetric = LegacyMetricFactory.createMetric({
      startDate,
      endDate,
      metricName: name,
      metricId: id,
    });
    return comparisonMetric;
  },
  createComparisonMetricList: ({ metrics }: { metrics: LegacyMetric[] }): LegacyMetric[] => {
    const comparisionMetrics = metrics.map((metric) =>
      LegacyMetricFactory.createComparisonMetric({ metric })
    );

    return comparisionMetrics;
  },
  createMetricList: ({
    numMetrics,
    startDate = format(
      sub(new Date(), {
        days: 30,
      }),
      DATE_FNS_ISO_8601_DATE_FORMAT
    ),
    endDate = format(new Date(), DATE_FNS_ISO_8601_DATE_FORMAT),
  }: {
    numMetrics: number;
    startDate?: string;
    endDate?: string;
  }): LegacyMetric[] => {
    return [...Array(numMetrics)].map(() =>
      LegacyMetricFactory.createMetric({ startDate, endDate })
    );
  },
  createMetricSourceDimension: (sourceDimensionCount?: number): LegacySourceDimension => {
    const name = `Source Dimension ${faker.commerce.productName()} ${faker.random.alphaNumeric()}`;
    return {
      name,
      total: faker.datatype.number(),
      percent: Math.random() > 0.5 ? round(Math.random() * 100, 1) : 0,
      groupByDate: sourceDimensionCount
        ? [...Array(50)].map((_, i) =>
            LegacyMetricFactory.createMetricValue({
              date: moment().subtract(i, 'days').format(MOMENT_ISO_8601_DATE_FORMAT),
            })
          )
        : undefined,
    };
  },
  createMetricValue: ({ date, value }: Partial<LegacyDateDimension>): LegacyDateDimension => {
    return {
      date: date ? date : moment().format(MOMENT_ISO_8601_DATE_FORMAT),
      value: value
        ? value
        : faker.datatype.number({
            min: 1000,
            max: 1000000,
          }),
    };
  },
};
