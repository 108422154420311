import React, { VFC } from 'react';

import { CurrencyCode, Locale } from '@attentive/locale-utils';
import { Box, Heading } from '@attentive/picnic';

import { MetricDataType } from '../../services/metrics/types';
import { round } from '../../utils';
import { formatMetricValue } from '../../utils/metricUtils';

import { PercentChangeIcon } from './PercentChangeIcon';

const getPercentageChange = (numA: number, numB: number) => {
  if (numA && numB) {
    return ((numA - numB) / numB) * 100;
  }
  return 0;
};

const PERCENT_CHANGE_DECIMAL_PRECISION = 1;

const getPercentChangeColor = (percentChange: number): string => {
  if (!percentChange || percentChange === 0) {
    return '$textDefault';
  }
  return percentChange > 0 ? '$textSuccess' : '$textWarning';
};

interface MetricWithPercentChangeProps {
  currentMetricValue: number;
  compareMetricValue: number;
  dataType: MetricDataType;
  locale: Locale;
  currencyCode?: CurrencyCode;
}

export const MetricWithPercentChange: VFC<MetricWithPercentChangeProps> = ({
  currentMetricValue,
  compareMetricValue,
  dataType,
  locale,
  currencyCode,
}) => {
  const percentChange = getPercentageChange(currentMetricValue, compareMetricValue);
  const valueToFormat =
    dataType === MetricDataType.MetricDataTypePercent
      ? currentMetricValue || null
      : currentMetricValue;
  const displayValue = formatMetricValue({
    value: valueToFormat,
    dataType,
    locale,
    currencyCode,
  });

  const showPercentChangeIcon = percentChange !== 0;

  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Heading variant="lg">{displayValue}</Heading>
      <Box
        css={{
          color: getPercentChangeColor(percentChange),
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          ml: '$space3',
        }}
      >
        {showPercentChangeIcon && (
          <>
            <PercentChangeIcon percentChange={percentChange} />
            <Heading variant="md">
              {Math.abs(round(percentChange, PERCENT_CHANGE_DECIMAL_PRECISION))}%
            </Heading>
          </>
        )}
      </Box>
    </Box>
  );
};
