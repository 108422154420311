/**
 * @generated SignedSource<<d95fe5a4a5fc5e7970ed8b8a87174a33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type isCompanyMultiRegion_AnalyticsUI_company$data = {
  readonly regions: ReadonlyArray<{
    readonly id: string;
  }> | null;
  readonly " $fragmentType": "isCompanyMultiRegion_AnalyticsUI_company";
};
export type isCompanyMultiRegion_AnalyticsUI_company$key = {
  readonly " $data"?: isCompanyMultiRegion_AnalyticsUI_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"isCompanyMultiRegion_AnalyticsUI_company">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "isCompanyMultiRegion_AnalyticsUI_company"
};

(node as any).hash = "49e973900d65a2507c4484ded61a8108";

export default node;
