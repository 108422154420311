import { BarLegendProps } from '@nivo/bar';

const MIN_LEFT_MARGIN = 40;
const MIN_TOP_MARGIN = 8;
const MIN_RIGHT_MARGIN = 20;
const MIN_BOTTOM_MARGIN = 32;
const MIN_RIGHT_MARGIN_LEGEND = MIN_RIGHT_MARGIN + 120;
const BOTTOM_LABEL_OFFSET = 40;
const LEFT_LABEL_OFFSET = 25;
const PX_PER_CHARACTER = 6;

export const calculateMargins = (
  xLabelPresent: boolean,
  yLabelPresent: boolean,
  legendEnabled: boolean,
  enableGridY: boolean,
  keys: string[]
) => {
  const longestKeyLength = Math.max(...keys.map((key) => key.length));
  const right = legendEnabled
    ? MIN_RIGHT_MARGIN_LEGEND + longestKeyLength * PX_PER_CHARACTER
    : MIN_RIGHT_MARGIN;

  const bottom = xLabelPresent ? MIN_BOTTOM_MARGIN + BOTTOM_LABEL_OFFSET : MIN_BOTTOM_MARGIN;
  const left = yLabelPresent || enableGridY ? MIN_LEFT_MARGIN + LEFT_LABEL_OFFSET : MIN_LEFT_MARGIN;

  return {
    top: MIN_TOP_MARGIN,
    right,
    bottom,
    left,
  };
};

const ONE_THOUSAND = 1000;
const ONE_MILLION = 1000000;
const ONE_BILLION = 1000000000;

export const formatValue = (val: number): string => {
  if (typeof val === 'number') {
    if (val >= ONE_BILLION) {
      return `${Math.round((val / ONE_BILLION) * 10) / 10}b`;
    }
    if (val >= ONE_MILLION) {
      return `${Math.round((val / ONE_MILLION) * 10) / 10}m`;
    }
    if (val >= ONE_THOUSAND) {
      return `${Math.round((val / ONE_THOUSAND) * 10) / 10}k`;
    }
  }
  return `${val}`;
};

const DEFAULT_LEGEND_X_OFFSET = 160;
const DEFAULT_LEGEND_Y_OFFSET = -2;
const DEFAULT_LEGEND_SYMBOL_SIZE = 20;

export const getLegend = (): BarLegendProps[] => {
  return [
    {
      dataFrom: 'keys',
      anchor: 'bottom-right',
      direction: 'column',
      justify: false,
      translateX: DEFAULT_LEGEND_X_OFFSET,
      translateY: DEFAULT_LEGEND_Y_OFFSET,
      itemsSpacing: DEFAULT_LEGEND_SYMBOL_SIZE / 2,
      itemWidth: DEFAULT_LEGEND_X_OFFSET - 10,
      itemHeight: DEFAULT_LEGEND_SYMBOL_SIZE,
      itemDirection: 'left-to-right',
      itemOpacity: 1,
      symbolSize: DEFAULT_LEGEND_SYMBOL_SIZE,
      toggleSerie: true,
      effects: [{ on: 'hover', style: { itemOpacity: 0.8 } }],
    },
  ];
};
