import { Locale } from '@attentive/locale-utils';

import { round } from './round';

export const MINIMUM_ROUNDED_VALUE = 0.01;

export const calculatePercent = ({
  numerator,
  denominator,
}: {
  numerator: number;
  denominator: number;
}): number => {
  if (denominator === 0) {
    return 0;
  }

  return (numerator / denominator) * 100;
};

export const formatPercent = (value: number | string, enableRounding = true): string => {
  const scaledValue = parseFloat(`${value}`) * 100;
  if (enableRounding) {
    const roundedValue = round(scaledValue, 2);
    if (roundedValue === 0 && scaledValue !== 0) {
      return `< ${MINIMUM_ROUNDED_VALUE}%`;
    }
    return `${roundedValue.toLocaleString()}%`;
  }

  return `${scaledValue.toLocaleString(undefined, {
    maximumFractionDigits: 20,
    minimumFractionDigits: 0,
  })}%`;
};

export const formatCurrency = ({
  value,
  locale,
  currencyCode,
  hasDecimals,
}: {
  value: number | string;
  locale: Locale;
  currencyCode?: string;
  hasDecimals?: boolean;
}) => {
  return value.toLocaleString(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: hasDecimals ? 2 : 0,
    maximumFractionDigits: hasDecimals ? 2 : 0,
  });
};
