/**
 * @generated SignedSource<<6bd7336f0d7220fc3321d96d5d24f272>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useMultiRegionMode_AnalyticsUI_company$data = {
  readonly selectableRegions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly value: string;
      };
    }>;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"isCompanyMultiRegion_AnalyticsUI_company">;
  readonly " $fragmentType": "useMultiRegionMode_AnalyticsUI_company";
};
export type useMultiRegionMode_AnalyticsUI_company$key = {
  readonly " $data"?: useMultiRegionMode_AnalyticsUI_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"useMultiRegionMode_AnalyticsUI_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useMultiRegionMode_AnalyticsUI_company",
  "selections": [
    {
      "alias": "selectableRegions",
      "args": [
        {
          "kind": "Literal",
          "name": "dimensionId",
          "value": "region"
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 250
        }
      ],
      "concreteType": "DimensionValueConnection",
      "kind": "LinkedField",
      "name": "dimensionValues",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DimensionValueEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DimensionValue",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "dimensionValues(dimensionId:\"region\",first:250)"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "isCompanyMultiRegion_AnalyticsUI_company",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Region",
          "kind": "LinkedField",
          "name": "regions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "0e30a889946989d32d88a738cdd4a733";

export default node;
