import _isCompanyMultiRegion_AnalyticsUI_company from "./__generated__/isCompanyMultiRegion_AnalyticsUI_company.graphql";
import { readInlineData } from 'react-relay';
import { graphql } from '@attentive/data';
import Fragment from './__generated__/isCompanyMultiRegion_AnalyticsUI_company.graphql';
_isCompanyMultiRegion_AnalyticsUI_company;
export const isCompanyMultiRegion = _ref => {
  let {
    companyRef
  } = _ref;
  const company = readInlineData(Fragment, companyRef);
  return company !== null && company !== void 0 && company.regions ? company.regions.length > 1 : false;
};