import _useMultiRegionMode_AnalyticsUI_company from "./__generated__/useMultiRegionMode_AnalyticsUI_company.graphql";
import { useFragment } from 'react-relay';
import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { graphql } from '@attentive/data';
import { isCompanyMultiRegion } from '../utils/isCompanyMultiRegion';
import useMultiRegionMode_AnalyticsUI_company from './__generated__/useMultiRegionMode_AnalyticsUI_company.graphql';
const UNKNOWN_REGION_VALUE = 'Unknown';
_useMultiRegionMode_AnalyticsUI_company;
export const useMultiRegionMode = _ref => {
  let {
    companyRef,
    hideUnknown = false
  } = _ref;
  const company = useFragment(useMultiRegionMode_AnalyticsUI_company, companyRef);
  const hasMultiRegion = isCompanyMultiRegion({
    companyRef: company
  });
  const enableReportingRegionCurrencyOverride = useCompanyFeatureFlag('ENABLE_REPORTING_REGION_CURRENCY_OVERRIDE');

  if (!company) {
    return {
      enabled: false,
      regions: []
    };
  }

  const {
    selectableRegions
  } = company;
  const regionDimensionValues = (selectableRegions === null || selectableRegions === void 0 ? void 0 : selectableRegions.edges.map(edge => edge.node.value).sort((a, b) => {
    if (a === UNKNOWN_REGION_VALUE) return 1;
    if (b === UNKNOWN_REGION_VALUE) return -1;
    return 0;
  })) || [];
  const filteredRegionDimensionValues = hideUnknown ? regionDimensionValues.filter(region => region !== UNKNOWN_REGION_VALUE) : regionDimensionValues;
  return {
    enabled: !enableReportingRegionCurrencyOverride && hasMultiRegion,
    regions: filteredRegionDimensionValues
  };
};